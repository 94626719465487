import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { Online } from 'react-detect-offline';
// import { StructuredData } from '.'
import { FIREBASE_STORAGE_PATH } from '../../utils';

//TODO: This variable is not even being used do we need i;
const MILAN_LOGO = `${FIREBASE_STORAGE_PATH}general%2Fseo%2FMilan-Logo-Blue.webp?alt=media&token=eacafc73-400d-4131-bde8-3b469aca0229`;

export function LHRVimeo() {
    return (
        <section className="py-5 milan-video">
            <div className="container">
                <h2 className="subheadlg milan-text-primary text-lg-center pb-0">
                    What’s your reason for getting laser hair removal?
                </h2>
                <div className="row">
                    <div className="col-lg-10 mx-auto  text-center embed-responsive embed-responsive-21by9">
                        {/* <iframe 
            src="https://player.vimeo.com/video/784633089?title=0&byline=0&portrait=0?title=0&byline=0&portrait=0" 
            style={{height: 'calc(50vw)', maxHeight: '450px'}} 
            className="video embed-responsive-item w-100" frameBorder="0" 
            webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen ></iframe> */}
                        <Online>
                            <Vimeo video="784633089" showTitle={false} showByline={false} />
                        </Online>
                    </div>
                </div>
            </div>
            {/* <StructuredData>
        {
          {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": "What’s your reason for getting laser hair removal?",
            "description": "Milan Laser is the nations expert in Laser Hair Removal. Get smooth, hair free skin with permanent results. Milan Laser has more than 220 locations in 28 states & the only company offering The Unlimited Package with Lifetime Guarantee.",
            "thumbnailUrl": "https://i.vimeocdn.com/video/1576189159-17b73571d6262ec27053b50788398b0f08e9c436d5d5425c423b40abe00d6b7a-d_295x166",
            "uploadDate": "2019-02-25T10:33:00-06:00",
            "duration": "PT2M19S",
            "publisher": {
              "@type": "Organization",
              "name": "Milan Laser Hair Removal",
              "logo": {
                "@type": "ImageObject",
                "url": `${MILAN_LOGO}`,
                "width": 190,
                "height": 80
              }
            },
            "contentUrl": "https://player.vimeo.com/video/319491411",
            "embedUrl": "https://vimeo.com/319491411",
            "interactionCount": "30"
          }
        }
      </StructuredData> */}
        </section>
    );
}
export default LHRVimeo;
