// useReviewCount.js
export function useReviewCount(currentMetro) {
    if (!currentMetro?.stores?.length) {
        return {
            avgRating: '0.0',
            reviewCount: '0',
        };
    }

    let count = 0;
    let ratingSum = 0;
    let reviewCount = 0;

    currentMetro.stores.forEach((store) => {
        if (store?.rating && store?.reviewCount) {
            count += 1;
            ratingSum += store.rating;
            reviewCount += store.reviewCount;
        }
    });

    const result = {
        avgRating: count ? (ratingSum / count).toString().substring(0, 3) : '0.0',
        reviewCount: reviewCount.toString(),
    };

    return result;
}
