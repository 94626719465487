import React from 'react'
import { CORPORATE_SITE } from '../../../utils'

export function PreQualify() {
  return (
    <section className="prequal py-5">
      <div className="container">
        <div className="row">
          <div className="offset-sm-2 col-12 col-sm-10 col-md-9 offset-lg-4 col-lg-7 offset-xl-6 col-xl-6 col-xxl-5">
            <h2 className="subheadlg milan-text-primary me-md-5 me-lg-0">See what you pre&#8209;qualify for in just a few steps!</h2>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-10 col-lg-8 col-xl-6">
            <div className="ms-3 ms-sm-0">
              <p>It takes less than 2 minutes and won’t affect your credit score.</p>
              <div className="mb-3 mb-lg-5 pt-3">
                <a href={`${CORPORATE_SITE}/finance/`} className="milan-btn-sm milan-cta-btn" target="_blank">Get Pre-Qualified Today</a>
              </div>
              <p className="mb-0 lh-base" style={{ fontSize: '.7rem' }}>Additional financing options provided by third-party lenders unaffiliated with Milan Laser Hair Removal.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PreQualify