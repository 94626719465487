import React from 'react'
import { Link } from 'gatsby'
// import { StaticImage } from 'gatsby-plugin-image'

export function FacialHair() {
    return (
        <section
            id="lhr-ideal-for-lipnchin"
            className="pt-milan-50 py-md-milan-50 py-lg-milan-150"
        >
            <div className="container">
                <div className="row">
                    <div className="offset-md-5 col-md-7 offset-lg-6 col-lg-6">
                        <h2 className="subheadlg milan-text-primary">
                            Why Laser Hair Removal <br className="d-sm-none" />{' '}
                            is Ideal for Facial Hair
                        </h2>
                        <p>
                            Laser hair removal for the face is less expensive
                            and time-consuming than a lifetime of shaving and
                            waxing.
                        </p>
                        <ul className="list-style-disc ul-list">
                            <li>
                                <strong>Be Fearless!</strong> Never worry about
                                the stubble on your lip or chin again. Get rid
                                of pesky facial hair forever.
                            </li>
                            <li>
                                <strong>Comfortable Treatments.</strong>{' '}
                                Goodbye, hot wax and razor burn! Our lasers work
                                alongside cooling technology for added comfort
                                during treatments.
                            </li>
                            <li>
                                <strong>Never shave again!</strong> With Milan’s
                                exclusive{' '}
                                <Link
                                    to="/specials/#guarantee"
                                    className="milan-text-primary"
                                >
                                    Unlimited Package™
                                </Link>
                                , we guarantee your results for life. Hormone
                                changes down the road causing hair growth? No
                                problem! We’ll take care of it for you with{' '}
                                <strong>no touch-up fees.</strong>
                            </li>
                        </ul>
                        <p className="d-none d-sm-block">
                            Laser hair removal is the{' '}
                            <strong>safest, most effective way</strong> to rid
                            unwanted lip or chin hair.
                        </p>
                        <Link to="#consult" className="milan-text-primary">
                            Schedule your{' '}
                            <span className="text-decoration-underline">
                                <strong>FREE consultation</strong>
                            </span>{' '}
                            to learn more about laser hair removal today!
                        </Link>
                    </div>
                </div>
            </div>
            {/* bg when stacked */}
            <div className="d-md-none stack-hero-bg" />
        </section>
    )
}
export default FacialHair
